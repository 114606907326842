import { AfterViewInit, Component } from '@angular/core';
import { AssetContainer, Engine, IWebXRHitResult, Mesh, Scene, SceneLoader, Vector3, WebXRAnchorSystem, WebXRDefaultExperience, WebXRFeaturesManager, WebXRHitTest } from 'babylonjs';


@Component({
  selector: 'app-doll-house',
  templateUrl: './doll-house.component.html',
  styleUrls: ['./doll-house.component.scss']
})
export class DollHouseComponent implements AfterViewInit {
  scene: Scene = null;
  currentPoint: IWebXRHitResult;
  anchorSystem: WebXRAnchorSystem;
  isPlaced: boolean = false;
  house: Mesh;

  ngAfterViewInit(): void {
    if (this.scene == null) {
      const canvas = <HTMLCanvasElement>document.querySelector('canvas');
      const engine: Engine = new Engine(canvas, true);

      this.createScene(engine, canvas).then(scene => {
        this.scene = scene;

        scene.executeWhenReady(() => {
          engine.runRenderLoop(() => {
            if (!this.isPlaced) {
              if (this.currentPoint) {
                this.house.position = this.currentPoint.position.clone();
                this.house.setEnabled(true);
              } else {
                this.house.setEnabled(false);
              }
            }
            scene.render();
          });
        });
      });
    }

    document.addEventListener('pinch', event => {
      console.log(event);
    });

  }

  async createScene(engine: Engine, canvas: HTMLCanvasElement): Promise<Scene> {
    const scene = new Scene(engine);
    scene.createDefaultCamera(false, false, true);
    scene.createDefaultLight();

    const xr = scene.createDefaultXRExperienceAsync({
      uiOptions: {
        sessionMode: "immersive-ar",
        referenceSpaceType: 'unbounded',
        optionalFeatures: ["hit-test", "anchors"]
      },
    }).then(xrExp => this.setupXRFeatures(xrExp));

    scene.onPointerDown = evt => this.placeHouseTouch();
    SceneLoader.LoadAssetContainer('../../../assets/', 'house.babylon', scene, c => this.loadHouse(c));

    return scene;
  }

  setupXRFeatures(xrExp: WebXRDefaultExperience) {
    const manager: WebXRFeaturesManager = xrExp.baseExperience.featuresManager;
    this.anchorSystem = <WebXRAnchorSystem>manager.enableFeature(WebXRAnchorSystem, 'latest')
    const hitTest: WebXRHitTest = <WebXRHitTest>manager.enableFeature(WebXRHitTest, 'latest', {});

    hitTest.onHitTestResultObservable.add(results => this.updateCurrentPoint(results));
  }

  placeHouseTouch() {
    if (!this.isPlaced && this.currentPoint) {
      this.isPlaced = true;
      console.log(this.isPlaced);
      this.anchorSystem.addAnchorPointUsingHitTestResultAsync(this.currentPoint);
    }
  }

  loadHouse(container: AssetContainer) {
    console.log('Loaded the house');
    container.addAllToScene();
    this.house = <Mesh>this.scene.getNodeByName('house');
    console.log(this.house);
    this.house.setEnabled(false);
    this.house.scaling = new Vector3(3, 3, 3);
  }

  updateCurrentPoint(results: IWebXRHitResult[]) {
    if (results.length) {
      this.currentPoint = results[0];
    } else {
      this.currentPoint = null;
    }
  }

}
