import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Scene } from '../../models/scene';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-ar-marker',
  templateUrl: './ar-marker.component.html',
  styleUrls: ['./ar-marker.component.scss']
})
export class ArMarkerComponent implements OnInit, OnDestroy {
  marker:Element;
  scene:Scene;

  aframe:HTMLScriptElement = null;
  arjs:HTMLScriptElement = null;

  isInfoOpen:boolean = false;

  constructor(private route: ActivatedRoute,private firestore: AngularFirestore) {}
  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
    this.scene = new Scene();
    this.marker = document.getElementById('marker');
    this.route.params.subscribe(params=>{
      this.retrieveSceneCode(params.id);
    });
    this.aframe = this.loadScript(environment.aframeLink);
    this.arjs = this.loadScript(environment.arjsMarkerLink);
  }

  retrieveSceneCode(id:String){
    const sceneData = this.firestore.doc<Scene>('arDocs/'+id);
    sceneData.valueChanges().subscribe(value=>{
      this.scene = value;
      this.marker.innerHTML = this.scene.scene.toString();
    });
  }

  loadScript(url:string):HTMLScriptElement{
    const script:HTMLScriptElement = document.createElement('script');

    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return script;
  }

}
