import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './components/contact/contact.component';
import { ImageTileComponent } from './components/image-tile/image-tile.component';
import { ArImageComponent } from './pages/ar-image/ar-image.component';
import { ArMarkerComponent } from './pages/ar-marker/ar-marker.component';
import { HomePageComponent } from './pages/home/home-page/home-page.component';
import { DesktopViewComponent } from './pages/home/views/desktop-view/desktop-view.component';
import { MobileViewComponent } from './pages/home/views/mobile-view/mobile-view.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DollHouseComponent } from './pages/doll-house/doll-house.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ArMarkerComponent,
    ArImageComponent,
    MobileViewComponent,
    DesktopViewComponent,
    ContactComponent,
    ImageTileComponent,
    DollHouseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatBottomSheetModule,
    MatListModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
