import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home/home-page/home-page.component';
import { ArMarkerComponent } from './pages/ar-marker/ar-marker.component';
import { DollHouseComponent } from './pages/doll-house/doll-house.component';

const routes: Routes = [
  {path:'', component:HomePageComponent},
  // {path:'**', component:HomePageComponent},
  {path:'marker-view/:id', component:ArMarkerComponent},
  {path:'doll-house', component:DollHouseComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
