import { Component, OnInit, AfterViewChecked, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {


  MOBILE_RES:number = 768;
  isMobileView:boolean = false;

  constructor(){
    if(window.innerWidth < this.MOBILE_RES){
      this.isMobileView = true;
    }
  }
}
