import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'image-tile',
  templateUrl: './image-tile.component.html',
  styleUrls: ['./image-tile.component.scss']
})
export class ImageTileComponent implements OnInit {
  @Input() imageUrl:String;
  @Input() link:String;
  @Input() slogan:String;

  gainedVisibility:IntersectionObserver

  constructor() { }

  ngOnInit(): void {
    console.log('hey');
  }

}
