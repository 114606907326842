<div style="display: flex;flex-direction: column;justify-items: center; margin: 5vmin;">
  <img src="../../assets/aerow-logo.png">
  <div style="height:2vmin"></div>
  <mat-card style="color:#e5e5e5;background-image: url(../../../../../assets/bkd.png);">
    <mat-mat-card-header><h2>What we do</h2></mat-mat-card-header>
    <mat-card-content style="text-align: center;">
      <h3 class="reveal-text">
        Build custom augmented reality advertising <br> products to fit any need.
      </h3>
    </mat-card-content>
    <mat-mat-card-header><h2>What is augmented reality</h2></mat-mat-card-header>
    <mat-card-content style="text-align: center;">
      <h3 class="reveal-text">
        Click any of the images on this page <br>to experience an interactive demo!
      </h3>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card style="color:#e5e5e5;background-image: url(../../../../../assets/bkd.png);">
    <mat-card-content style="text-align: center;">
      <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg">
      <h2 class="reveal-text">
        Don't just give a business card...<br> leave an impression
      </h2>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card style="color:#e5e5e5;background-image: url(../../../../../assets/bkd.png);">
    <mat-card-content style="text-align: center;">
      <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg">
      <h2 class="reveal-text">
        Reach potential clients in<br> a new dimension
      </h2>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card style="color:#e5e5e5;background-image: url(../../../../../assets/bkd.png);">
    <mat-card-content style="text-align: center;">
      <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg">
      <h2 class="reveal-text">
        Don't just give a business card...<br> leave an impression
      </h2>
    </mat-card-content>
  </mat-card>
  <div style="height:2vmin"></div>
  <app-contact></app-contact>
</div>
<div style="color:#e5e5e5;background-color: #2d4059;width: 100%;text-align: center; padding: 4vmin;overflow-x:hidden">
  <span>{{name}} | {{email}}</span>
  <br>
  <span>&copy; Copyright 2020, Aerow Design</span>
</div>
