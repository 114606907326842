// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBpnp0MELLu60tOrB8WRk1oLYyl2BeQfv4",
    authDomain: "aerow.space",
    databaseURL: "https://augmented-reality-platform.firebaseio.com",
    projectId: "augmented-reality-platform",
    storageBucket: "augmented-reality-platform.appspot.com",
    messagingSenderId: "846681723137",
    appId: "1:846681723137:web:9d1be444a83efc0dc00b00",
    measurementId: "G-ZQEDT6BE86"
  },
  contactinfo:{
    name:'Chase Taranto',
    email:'chase.taranto@aerow.space',
    website:'aerow.space'
  },
  aframeLink:'https://cdn.jsdelivr.net/gh/aframevr/aframe@1c2407b26c61958baa93967b5412487cd94b290b/dist/aframe-master.min.js',
  arjsMarkerLink:'https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar.js',
  arjsImageLink:'https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
