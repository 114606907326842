import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-view',
  templateUrl: './mobile-view.component.html',
  styleUrls: ['./mobile-view.component.scss']
})
export class MobileViewComponent implements OnInit {
  name:String;
  email:String;

constructor() {
  this.name = environment.contactinfo.name;
  this.email = environment.contactinfo.email;
}

  ngOnInit(): void {
  }

}
