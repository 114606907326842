<a-scene embedded arjs vr-mode-ui="enabled: false;">
  <a-assets>
  </a-assets>
  <a-marker preset="hiro" markerhandler>
    <a-entity rotation="-90 0 0" id="marker">
    </a-entity>
  </a-marker>
  <a-entity camera></a-entity>
</a-scene>
<button mat-fab color="primary" style="position: absolute;bottom: 0;right: 0;margin:2vmin" (click)="isInfoOpen = true">
  <mat-icon>more_horiz</mat-icon>
</button>

<div style="position: absolute;bottom: 0;left: 0;width: 100%;height: auto;" *ngIf="isInfoOpen">
  <mat-card>
    <mat-card-content *ngFor="let desc of scene.description"><h1>{{desc.title}}</h1>{{desc.info}}</mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="warn" (click)="isInfoOpen = false">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<!-- <div style="position: absolute;top: 0;left: 0;background-color: orange;width: 100%;height: 10vmin;"></div> -->
