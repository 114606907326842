<!-- <mat-grid-list cols="3" rowHeight="2:1" gutterSize="1vmin" class="grid-container">
  <mat-grid-tile [colspan]=2 [rowspan]=2>
    <image-tile
      [imageUrl]="'https://image.shutterstock.com/image-photo/augmented-reality-ar-information-technology-600w-755908510.jpg'">
    </image-tile>
  </mat-grid-tile>
  <mat-grid-tile [rowspan]=2 class="text-tile">
    <h2>Don't just give a business card... give an impression</h2>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=1 [rowspan]=1 class="red" style="width: 100%;">
    <img src="../../assets/aerow-logo.png" style="width: 100%;">
  </mat-grid-tile>
  <mat-grid-tile [colspan]=2 [rowspan]=2>
    <image-tile
      [imageUrl]="'https://image.shutterstock.com/image-photo/augmented-reality-ar-information-technology-600w-755908510.jpg'">
    </image-tile>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=1 [rowspan]=1 style="background-color: #383232;">
    <h2 style="margin-right: 5px;">Contact</h2>
    Chase Taranto
    <br>
    chase.taranto@aerow.space
    <br>
    (850) 228-2645
  </mat-grid-tile>
  <mat-grid-tile [colspan]=2 [rowspan]=2>
    <image-tile
      [imageUrl]="'https://image.shutterstock.com/image-photo/augmented-reality-ar-information-technology-600w-755908510.jpg'">
    </image-tile>
  </mat-grid-tile>
  <mat-grid-tile [rowspan]=2>
      <h2>Reach potential clients in a new dimension</h2>
  </mat-grid-tile>
</mat-grid-list> -->
<div fxLayout="column" style="margin: auto;">
  <div fxLayout="row">
    <image-tile
      [imageUrl]="'https://image.shutterstock.com/image-photo/augmented-reality-ar-information-technology-600w-755908510.jpg'">
    </image-tile>
    <div class="text-tile">
      <h2>Don't just give a business card... give an impression</h2>
    </div>
  </div>
  <div fxLayout="row">
    <div fxLayout="column">
      <img src="../../assets/BuisnessCard.png" style="width: 100%;">
      <!-- <div style="background-color: #383232;color: white;text-align: center;" fxLayout="row">
        <h2 style="margin-right: 5px;">Contact</h2>
        Chase Taranto
        <br>
        chase.taranto@aerow.space
        <br>
        (850) 228-2645
      </div> -->
    </div>
    <image-tile
      [imageUrl]="'https://image.shutterstock.com/image-photo/augmented-reality-ar-information-technology-600w-755908510.jpg'">
    </image-tile>
  </div>
</div>
<app-contact style="padding: 10px;"></app-contact>
<div style="color:#e5e5e5;background-color: #2d4059;width: 100%;text-align: center; padding: 4vmin;overflow-x:hidden">
  <span>&copy; Copyright 2020, Aerow Design</span>
</div>
