<mat-card>
  <mat-card-header>
    <div mat-card-avatar><img src="../../../assets/icon.png"></div>
    <mat-card-title>Get in touch</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="contact-form" style="width: 100%;height:100%;">
      <mat-form-field class="contact-name">
        <mat-label>Name</mat-label>
        <input matInput placeholder="">
      </mat-form-field>
      <div></div>
      <mat-form-field class="contact-name">
        <mat-label>Email</mat-label>
        <input matInput placeholder="">
      </mat-form-field>
      <br>
      <mat-form-field class="contact-body" style="width:100%; height:52vmin">
        <mat-label>Message</mat-label>
        <textarea matInput placeholder="" style="height: 50vmin;"></textarea>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button style="background-color: #2d4059;color:#e5e5e5">Submit</button>
  </mat-card-actions>
</mat-card>

